import React, { Component } from "react";
import { Formik,FieldArray,Field,ErrorMessage,getIn  } from 'formik';
import { Form,Button,Container,Col,InputGroup,FormControl ,Collapse  } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useFormikContext } from 'formik';

import "./Schedule.css";
import Authenticator from "../../services/Authenticator";

var day = {mon:"Monday", tue:"Tuesday", wed : "Wednesday" , thu: "Thursday", fri: "Friday", sat : "Saturday", sun : "Sunday"};

const ScheduleHolidayPolicy = (props) => {
  
  const { handleSubmit, setFieldValue } = useFormikContext();

  return (<Field>
      {({ field, form }) => (
      <div>
        <Form.Group>
          <label>
          <input 
            type="checkbox"
            disabled = {props.isDisabled} 
            checked={field.value.schedule_policies.allow_special_holiday=="1"}
            onChange={() => form.setFieldValue('schedule_policies.allow_special_holiday', field.value.schedule_policies.allow_special_holiday==1?0:1 )}
          />

          Special Holiday &nbsp;
        </label>
        <label>
          <input 
            type="checkbox"
            disabled = {props.isDisabled} 
            checked={field.value.schedule_policies.allow_legal_holiday=="1"}
            onChange={() => form.setFieldValue('schedule_policies.allow_legal_holiday', field.value.schedule_policies.allow_legal_holiday==1?0:1)}
          />
          Legal Holiday &nbsp;
        </label>
        </Form.Group>
        { props.showAssignButton ? 
          <button className="btn btn-secondary" onClick={(e)=> { setFieldValue('action', 'assign_schedule_holiday_policy'); handleSubmit(e); }}>
            <i className="fa fa-tag" /> Assign to all employees
          </button>
          :
          null
        }
      </div>
      )}
    </Field>);
}

const SchedulePolicy = (props) => {
  
  const { handleSubmit, setFieldValue } = useFormikContext();

    return (<Field>
        {({ field, form }) => (
        <div>
          <Form.Group>
            <label>
              <input 
                type="checkbox"
                checked={field.value.schedule_policies.allow_undertime=="1"}
                disabled = {props.isDisabled} 
                onChange={() => form.setFieldValue('schedule_policies.allow_undertime', field.value.schedule_policies.allow_undertime==1?0:1 )}
              />

              Undertime &nbsp;
            </label>
            <label>
              <input 
                type="checkbox"
                checked={field.value.schedule_policies.allow_late=="1"}
                disabled = {props.isDisabled}
                onChange={() => form.setFieldValue('schedule_policies.allow_late', field.value.schedule_policies.allow_late==1?0:1)}
              />
              Tardiness &nbsp;
            </label>
            <label>
              <input 
                type="checkbox"
                checked={field.value.schedule_policies.allow_night_diff=="1"}
                disabled = {props.isDisabled}
                onChange={()  => {
                form.setFieldValue('schedule_policies.allow_night_diff',field.value.schedule_policies.allow_night_diff==1?0:1)}}
              />
              Night Differential &nbsp;
            </label>
          </Form.Group>
          { props.showAssignButton ? 
            <button className="btn btn-secondary" onClick={(e)=> { setFieldValue('action','assign_schedule_policy'); handleSubmit(e); }}>
              <i className="fa fa-tag" /> Assign to all employees
            </button>
            :
            null
          }
        </div>
        )}
      </Field>);
}

const WorkDays = (props) => {
    console.log(props);
    return (<div>   <WorkDay day="mon" isDisabled ={props.isDisabled}/>
                    <WorkDay day="tue" isDisabled ={props.isDisabled}/>
                    <WorkDay day="wed" isDisabled ={props.isDisabled}/>
                    <WorkDay day="thu" isDisabled ={props.isDisabled}/>
                    <WorkDay day="fri" isDisabled ={props.isDisabled}/>
                    <WorkDay day="sat" isDisabled ={props.isDisabled}/>
                    <WorkDay day="sun" isDisabled ={props.isDisabled}/></div>);
}


const WorkDay = (props) => {
    return (<Field>
        {({ field, form }) => (
        <FieldArray
        name="cst_schedule_details"
        render={arrayHelpers => (
          <label>
            <input
              type="checkbox"
              checked={field.value.work_days.includes(props.day)}
              disabled ={props.isDisabled == true}
              onChange={() => {
                  if(field.value.work_days.includes(props.day)){
                    // REMOVE
                    // const indexValue = field.value.work_days.find(value => value == props.day);
                    const nextValue = field.value.work_days.filter(value => value !== props.day);
                    form.setFieldValue('work_days', nextValue);
                    arrayHelpers.remove(eval('field.value.wd.'+props.day+'.index'));
                  }else{
                    // ADD
                    const index = field.value.work_days.length;
                    form.setFieldValue('wd.'+props.day+'.index',index);

                    const nextValue = field.value.work_days.concat(props.day);
                    form.setFieldValue('work_days', nextValue);

                    arrayHelpers.push({break_time : "",start_time : "",end_time : "",start_flexy_time : "",end_flexy_time : "" });
                  }
              }}
            />
            {eval('day.'+props.day)} &nbsp;
            </label>
            )}
    />
    
        )}
      </Field>);
}



const StandardSchedDetailsForm = (props) => {
  var break_hours = new Date(); 
   return (<Field>
        {({ field, form }) => (
          <div>
          <Form.Row>
            <Col sm={4}>
              <Form.Label>On Duty :</Form.Label>
              <DatePicker 
                    className="form-control"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    selected={field.value.std_schedule_details[0].start_time}              
                    onChange={(date) => onSelectTimeHandlerStd(date,0,form.setFieldValue,'std_')}
                  />
              <Form.Control.Feedback type="invalid">
                <ErrorMessage component="div" name="std_schedule_details[0].start_time" className="input-feedback" />
              </Form.Control.Feedback>
            </Col>
            <Col sm={4}>
            <Form.Label>Off Duty :</Form.Label>
                <DatePicker 
                      className="form-control"                      
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      minTime={break_hours.setHours(0,0)}
                      maxTime={break_hours.setHours( 1)} 
                      selected={field.value.std_schedule_details[0].end_time}                
                      onChange={date => form.setFieldValue('std_schedule_details[0].end_time', date)}
                    />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage component="div" name="std_schedule_details[0].end_time" className="input-feedback" />
                </Form.Control.Feedback>
            </Col>
            <Col sm={4}>
            <Form.Label>Break :</Form.Label>
                <DatePicker 
                      className="form-control"                      
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Break"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm" 
                      minTime={break_hours.setHours(0,0)}
                      maxTime={break_hours.setHours( 1)} 
                      selected={field.value.std_schedule_details[0].break_time}                
                      onChange={date => form.setFieldValue('std_schedule_details[0].break_time', date)}
                    />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage component="div" name="std_schedule_details[0].break_time" className="input-feedback" />
                </Form.Control.Feedback>
            </Col>
            </Form.Row>
          </div>
        )}
      </Field>);
}

const StandardSchedDetailsFormWithTimezone = (props) => {
  var break_hours = new Date(); 
    return (<Field>
        {({ field, form }) => (
          <div>
          <Form.Row>
            <Col sm={4}>
              <Form.Label>On Duty :</Form.Label>
              <DatePicker 
                    className="form-control"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    selected={field.value.std_schedule_details[0].start_time}              
                    // onChange={(date) => onSelectTimeHandlerStd(date,0,form.setFieldValue,'std_')}
                    onChange={(date) => {onSelectTimeHandlerStd(date,0,form.setFieldValue,'std_');
                onSelectTimeHandlerStdOnTimezone(date,0,form.setFieldValue,'pov_',props.offset_data)}}
                  />
              <Form.Control.Feedback type="invalid">
                <ErrorMessage component="div" name="std_schedule_details[0].start_time" className="input-feedback" />
              </Form.Control.Feedback>
            </Col>
            <Col sm={4}>
            <Form.Label>Off Duty :</Form.Label>
                <DatePicker 
                      className="form-control"                      
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm" 
                      selected={field.value.std_schedule_details[0].end_time}                
                      onChange={date => form.setFieldValue('std_schedule_details[0].end_time', date)}
                    />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage component="div" name="std_schedule_details[0].end_time" className="input-feedback" />
                </Form.Control.Feedback>
            </Col>
            <Col sm={4}>
            <Form.Label>Break :</Form.Label>
                <DatePicker 
                      className="form-control"                      
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Break"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm" 
                      minTime={break_hours.setHours(0,0)}
                      maxTime={break_hours.setHours( 1)} 
                      selected={field.value.std_schedule_details[0].break_time}                
                      onChange={date => form.setFieldValue('std_schedule_details[0].break_time', date)}
                    />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage component="div" name="std_schedule_details[0].break_time" className="input-feedback" />
                </Form.Control.Feedback>
            </Col>
            </Form.Row>
                    {/* ON TIMEZONE POV //////////////////////////////////////////////////*/}
        { true ? 
        <Collapse in={props.open_contrast}>
          <div className="changeSchedulePOV">
          <Form.Row>
              <Form.Group as={Col} sm={4}>
                  <Form.Label>On Duty - {props.pov_timezone_info}</Form.Label> 
                  <DatePicker 
                    className="form-control"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    placeholder="On Duty"
                    selected={field.value?.pov_schedule_details[0]?.start_time != undefined ?field.value.pov_schedule_details[0].start_time  : null}              
                    readOnly
                  />
                  <Form.Control.Feedback type="invalid">
                    <ErrorMessage component="div" name={`pov_schedule_details.start_time`}className="input-feedback" />
                  </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm={4} >
                  <Form.Label>Off Duty</Form.Label>
                  <DatePicker 
                    className="form-control"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    placeholder="On Duty"
                    selected={field.value?.pov_schedule_details[0]?.end_time != undefined ?field.value.pov_schedule_details[0].end_time : null}              
                    readOnly
                  />
                  <Form.Control.Feedback type="invalid">
                    <ErrorMessage component="div" name={`pov_schedule_details.end_time`}className="input-feedback"/>
                  </Form.Control.Feedback>
              </Form.Group>
          </Form.Row>
      
          
          </div>
          </Collapse>
          :null
        }
          </div>
        )}
      </Field>);
  }

const FlexibleSchedDetailsForm = (props) => {
  var break_hours = new Date(); 
   return (<Field>
        {({ field, form }) => (
          <div>
          <Form.Row>
            <Col sm={4}>
                <Form.Label>On Duty :</Form.Label>
                <DatePicker 
                      className="form-control"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      placeholder="On Duty"
                      selected={field.value.flx_schedule_details[0].start_time}              
                      onChange={(date) => onSelectTimeHandlerStd(date,0,form.setFieldValue,'flx_')}
                    />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage component="div" name="flx_schedule_details[0].start_time" className="input-feedback" />
                </Form.Control.Feedback>
            </Col>

            <Col sm={4}>
                <Form.Label>Off Duty :</Form.Label>
                <DatePicker 
                      className="form-control"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      placeholder="On Duty"
                      selected={field.value.flx_schedule_details[0].end_time}                
                      onChange={date => form.setFieldValue('flx_schedule_details[0].end_time', date)}
                    />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage component="div" name="flx_schedule_details[0].end_time" className="input-feedback" />
                </Form.Control.Feedback>
            </Col>
        </Form.Row>

        <Form.Row>
            <Col sm={4}>
                <Form.Label>Flexi Start :</Form.Label>
                    <DatePicker 
                      className="form-control"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      placeholder="On Duty"
                      selected={field.value.flx_schedule_details[0].start_flexy_time}                
                      onChange={(date) => onSelectTimeHandlerFlexi(date,0,form.setFieldValue,'flx_')}
                    />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage component="div" name="flx_schedule_details[0].start_flexy_time" className="input-feedback" />
                </Form.Control.Feedback>
            </Col>

            <Col sm={4}>
                <Form.Label>Flexi End :</Form.Label>
                    <DatePicker 
                      className="form-control"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      placeholder="On Duty"
                      selected={field.value.flx_schedule_details[0].end_flexy_time}                
                      onChange={date => form.setFieldValue('flx_schedule_details[0].end_flexy_time', date)}
                    />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage component="div" name="flx_schedule_details[0].end_flexy_time" className="input-feedback" />
                </Form.Control.Feedback>
            </Col>

            <Col sm={4}>
                <Form.Label>Break :</Form.Label>
                    <DatePicker 
                      className="form-control"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      placeholder="Break"
                      minTime={break_hours.setHours(0,0)}
                      maxTime={break_hours.setHours( 1)} 
                      selected={field.value.flx_schedule_details[0].break_time}                
                      onChange={date => form.setFieldValue('flx_schedule_details[0].break_time', date)}
                    />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage component="div" name="flx_schedule_details[0].break_time" className="input-feedback" />
                </Form.Control.Feedback>
            </Col>
        </Form.Row>
          </div>
        )}
      </Field>);
}

  const FlexibleSchedDetailsFormWithTimezone = (props) => {
    var break_hours = new Date(); 
    return (<Field>
      
        {({ field, form }) => (
        
          <div>
          <Form.Row>
            <Col sm={4}>
                <Form.Label>On Duty :</Form.Label>
                <DatePicker 
                      className="form-control"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      readOnly = {props.isDisabled}
                      placeholder="On Duty"
                      selected={field.value.flx_schedule_details[0].start_time}              
                      onChange={(date) => {onSelectTimeHandlerStd(date,0,form.setFieldValue,'flx_'); console.log()
                      onSelectTimeHandlerStdOnTimezone(date,0,form.setFieldValue,'pov_',props.offset_data)}}
                    />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage component="div" name="flx_schedule_details[0].start_time" className="input-feedback" />
                </Form.Control.Feedback>
            </Col>

            <Col sm={4}>
                <Form.Label>Off Duty :</Form.Label>
                <DatePicker 
                      className="form-control"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      readOnly = {props.isDisabled}
                      placeholder="On Duty"
                      selected={field.value.flx_schedule_details[0].end_time}                
                      onChange={date => form.setFieldValue('flx_schedule_details[0].end_time', date)}
                    />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage component="div" name="flx_schedule_details[0].end_time" className="input-feedback" />
                </Form.Control.Feedback>
            </Col>
        </Form.Row>

        <Form.Row>
            <Col sm={4}>
                <Form.Label>Flexi Start :</Form.Label>
                    <DatePicker 
                      className="form-control"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      readOnly = {props.isDisabled}
                      placeholder="On Duty"
                      selected={field.value.flx_schedule_details[0].start_flexy_time}                
                      //  onChange={(date) => onSelectTimeHandlerFlexi(date,0,form.setFieldValue,'flx_')}
                      onChange={(date) => {onSelectTimeHandlerFlexi(date,0,form.setFieldValue,'flx_');
                      onSelectTimeHandlerStdOnTimezone(date,0,form.setFieldValue,'pov_',props.offset_data, true)}}
                    />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage component="div" name="flx_schedule_details[0].start_flexy_time" className="input-feedback" />
                </Form.Control.Feedback>
            </Col>

            <Col sm={4}>
                <Form.Label>Flexi End :</Form.Label>
                    <DatePicker 
                      className="form-control"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      readOnly = {props.isDisabled}
                      placeholder="On Duty"
                      selected={field.value.flx_schedule_details[0].end_flexy_time}                
                      onChange={date => form.setFieldValue('flx_schedule_details[0].end_flexy_time', date)}
                    />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage component="div" name="flx_schedule_details[0].end_flexy_time" className="input-feedback" />
                </Form.Control.Feedback>
            </Col>

            <Col sm={4}>
                <Form.Label>Break :</Form.Label>
                    <DatePicker 
                      className="form-control"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      readOnly = {props.isDisabled}
                      placeholder="Break"
                      minTime={break_hours.setHours(0,0)}
                      maxTime={break_hours.setHours( 1)} 
                      selected={field.value.flx_schedule_details[0].break_time}                
                      onChange={date => form.setFieldValue('flx_schedule_details[0].break_time', date)}
                    />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage component="div" name="flx_schedule_details[0].break_time" className="input-feedback" />
                </Form.Control.Feedback>
            </Col>
        </Form.Row>
        {/* ON TIMEZONE POV //////////////////////////////////////////////////*/}
        {true ? 
        <Collapse in={props.open_contrast}>
          <div className="changeSchedulePOV">
          <Form.Row>
              <Form.Group as={Col} sm={4}>
                  <Form.Label>On Duty - {props.pov_timezone_info}</Form.Label> 
                  <DatePicker 
                    className="form-control"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    placeholder="On Duty"
                    selected={field.value?.pov_schedule_details[0]?.start_time != undefined ?field.value.pov_schedule_details[0].start_time  : null}              
                    readOnly
                  />
                  <Form.Control.Feedback type="invalid">
                    <ErrorMessage component="div" name={`pov_schedule_details.${0}.start_time`}className="input-feedback" />
                  </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm={4} >
                  <Form.Label>Off Duty</Form.Label>
                  <DatePicker 
                    className="form-control"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    placeholder="On Duty"
                    selected={field.value?.pov_schedule_details[0]?.end_time != undefined ?field.value.pov_schedule_details[0].end_time : null}              
                    readOnly
                  />
                  <Form.Control.Feedback type="invalid">
                    <ErrorMessage component="div" name={`pov_schedule_details.${0}.end_time`}className="input-feedback"/>
                  </Form.Control.Feedback>
              </Form.Group>
          </Form.Row>
          <Form.Row>
              <Form.Group as={Col} sm={4} >
              <Form.Label>Flexi Start</Form.Label>
                  <DatePicker 
                    className="form-control"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    placeholder="On Duty"
                    selected={field.value?.pov_schedule_details[0]?.start_flexy_time ? field.value.pov_schedule_details[0].start_flexy_time: null}              
                    readOnly
                  />
                  <Form.Control.Feedback type="invalid">
                    <ErrorMessage component="div" name={`pov_schedule_details.${0}.start_flexy_time`}className="input-feedback" />
                  </Form.Control.Feedback>
              </Form.Group>
    
              <Form.Group as={Col} sm={4}>
              <Form.Label>Flexi End </Form.Label>
                  <DatePicker 
                    className="form-control"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    placeholder="On Duty"
                    selected={field.value?.pov_schedule_details[0]?.end_flexy_time ? field.value.pov_schedule_details[0].end_flexy_time: null}              
            
                    readOnly
                  />
                  <Form.Control.Feedback type="invalid">
                    <ErrorMessage component="div" name={`pov_schedule_details.${0}.end_flexy_time`}className="input-feedback" />
                  </Form.Control.Feedback>
              </Form.Group>
{/*     
              <Form.Group as={Col} sm={4}>
              <h6>Break </h6>
                  <DatePicker 
                    className="form-control"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    placeholder="On Duty"
                    selected={field.value?.pov_schedule_details[props.index]?.break_time != undefined ?field.value.pov_schedule_details[props.index].break_time : null}              

                    // selected={field.value.pov_schedule_details[props.index].break_time}              
                    readOnly
                  />
                  <Form.Control.Feedback type="invalid">
                    <ErrorMessage component="div" name={`pov_schedule_details.${props.index}.break_time`}className="input-feedback" />
                  </Form.Control.Feedback>
          </Form.Group> */}
          </Form.Row>
          
          </div>
          </Collapse>
          :null
        }
         </div>
       )}
     </Field>);
}

const Scheduledetails = (props) => {
  var break_hours = new Date(); 
    return (<Field>
        {({ field, form }) => (
          <div className="stripe">
            {console.log(field.value)}
          <Form.Label>{eval('day.'+props.day)} :</Form.Label>
        <Form.Row>
            <Form.Group as={Col} sm={4}>
                <h6>On Duty</h6>
                <DatePicker 
                  className="form-control"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  placeholder="On Duty"
                  selected={field.value.cst_schedule_details[props.index].start_time}              
                  onChange={(date) => onSelectTimeHandlerStd(date,props.index,form.setFieldValue,'cst_')}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage component="div" name={`cst_schedule_details.${props.index}.start_time`}className="input-feedback" />
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} sm={4} >
                <h6>Off Duty</h6>
                <DatePicker 
                  className="form-control"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  placeholder="On Duty"
                  selected={field.value.cst_schedule_details[props.index].end_time}              
                  onChange={date => form.setFieldValue('cst_schedule_details['+props.index+'].end_time', date)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage component="div" name={`cst_schedule_details.${props.index}.end_time`}className="input-feedback"/>
                </Form.Control.Feedback>
            </Form.Group>
        </Form.Row>
        <Form.Row>
            <Form.Group as={Col} sm={4} >
            <h6>Flexi Start</h6>
                <DatePicker 
                  className="form-control"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  placeholder="On Duty"
                  selected={field.value.cst_schedule_details[props.index].start_flexy_time}              
                  onChange={(date) => onSelectTimeHandlerFlexi(date,props.index,form.setFieldValue,'cst_')}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage component="div" name={`cst_schedule_details.${props.index}.start_flexy_time`}className="input-feedback" />
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} sm={4}>
            <h6>Flexi End </h6>
                <DatePicker 
                  className="form-control"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  placeholder="On Duty"
                  selected={field.value.cst_schedule_details[props.index].end_flexy_time}              
                  onChange={date => form.setFieldValue('cst_schedule_details['+props.index+'].end_flexy_time', date)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage component="div" name={`cst_schedule_details.${props.index}.end_flexy_time`}className="input-feedback" />
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} sm={4}>
            <h6>Break </h6>
                <DatePicker 
                  className="form-control"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  placeholder="On Duty"
                  minTime={break_hours.setHours(0,0)}
                  maxTime={break_hours.setHours( 1)} 
                  selected={field.value.cst_schedule_details[props.index].break_time}              
                  onChange={date => form.setFieldValue('cst_schedule_details['+props.index+'].break_time', date)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage component="div" name={`cst_schedule_details.${props.index}.break_time`}className="input-feedback" />
                </Form.Control.Feedback>
        </Form.Group>
        </Form.Row>
        </div>
        )}
      </Field>);
}

const ScheduledetailsWithTimezone = (props) => {
  var break_hours = new Date(); 
  return (<Field>
      {({ field, form }) => (
        <div className="stripe">
             {console.log(field.value)}
        <Form.Label><b>{eval('day.'+props.day)} :</b></Form.Label>
      <Form.Row>
          <Form.Group as={Col} sm={4}>
              <h6>On Duty</h6>
              <DatePicker 
                className="form-control"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                timeCaption="Time"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                placeholder="On Duty"
                selected={field.value.cst_schedule_details[props.index].start_time}              
                onChange={(date) => {onSelectTimeHandlerStd(date,props.index,form.setFieldValue,'cst_');
                onSelectTimeHandlerStdOnTimezone(date,props.index,form.setFieldValue,'pov_',props.offset_data)}}
              />
              <Form.Control.Feedback type="invalid">
                <ErrorMessage component="div" name={`cst_schedule_details.${props.index}.start_time`}className="input-feedback" />
              </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} sm={4} >
              <h6>Off Duty</h6>
              <DatePicker 
                className="form-control"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                timeCaption="Time"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                placeholder="On Duty"
                selected={field.value.cst_schedule_details[props.index].end_time}              
                onChange={date => {form.setFieldValue('cst_schedule_details['+props.index+'].end_time', date)
                onSelectTimeHandlerSchedule(date,props.index,form.setFieldValue,"pov_","end_time",props.offset_data)
              }}
              />
              <Form.Control.Feedback type="invalid">
                <ErrorMessage component="div" name={`cst_schedule_details.${props.index}.end_time`}className="input-feedback"/>
              </Form.Control.Feedback>
          </Form.Group>
      </Form.Row>
      <Form.Row>
          <Form.Group as={Col} sm={4} >
          <h6>Flexi Start</h6>
              <DatePicker 
                className="form-control"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                timeCaption="Time"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                placeholder="On Duty"
                selected={field.value.cst_schedule_details[props.index].start_flexy_time}              
                onChange={(date) => {onSelectTimeHandlerFlexi(date,props.index,form.setFieldValue,'cst_');
                onSelectTimeHandlerStdOnTimezone(date,props.index,form.setFieldValue,'pov_',props.offset_data, true)
              }}
              />
              <Form.Control.Feedback type="invalid">
                <ErrorMessage component="div" name={`cst_schedule_details.${props.index}.start_flexy_time`}className="input-feedback" />
              </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} sm={4}>
          <h6>Flexi End </h6>
              <DatePicker 
                className="form-control"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                timeCaption="Time"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                placeholder="On Duty"
                selected={field.value.cst_schedule_details[props.index].end_flexy_time}              
                onChange={date =>{ form.setFieldValue('cst_schedule_details['+props.index+'].end_flexy_time', date);
                onSelectTimeHandlerSchedule(date,props.index,form.setFieldValue,"pov_","end_flexy_time",props.offset_data)}}
              />
              <Form.Control.Feedback type="invalid">
                <ErrorMessage component="div" name={`cst_schedule_details.${props.index}.end_flexy_time`}className="input-feedback" />
              </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} sm={4}>
          <h6>Break </h6>
              <DatePicker 
                className="form-control"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                timeCaption="Time"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                placeholder="On Duty"
                minTime={break_hours.setHours(0,0)}
                maxTime={break_hours.setHours( 1)} 
                selected={field.value.cst_schedule_details[props.index].break_time}              
                onChange={date => {form.setFieldValue('cst_schedule_details['+props.index+'].break_time', date)
              }}
              />
              <Form.Control.Feedback type="invalid">
                <ErrorMessage component="div" name={`cst_schedule_details.${props.index}.break_time`}className="input-feedback" />
              </Form.Control.Feedback>
      </Form.Group>
      </Form.Row>
        {/* ON TIMEZONE POV //////////////////////////////////////////////////*/}
        {props.on_approval || props.show_pov? 
        <Collapse in={props.open_contrast}>
          <div className="changeSchedulePOV">
          <Form.Row>
              <Form.Group as={Col} sm={4}>
                  <h6>On Duty - {props.pov_timezone_info}</h6> 
                  <DatePicker 
                    className="form-control"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    placeholder="On Duty"
                    selected={field.value?.pov_schedule_details[props.index]?.start_time != undefined ?field.value.pov_schedule_details[props.index].start_time  : null}              
                    readOnly
                  />
                  <Form.Control.Feedback type="invalid">
                    <ErrorMessage component="div" name={`pov_schedule_details.${props.index}.start_time`}className="input-feedback" />
                  </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm={4} >
                  <h6>Off Duty</h6>
                  <DatePicker 
                    className="form-control"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    placeholder="On Duty"
                    selected={field.value?.pov_schedule_details[props.index]?.end_time != undefined ?field.value.pov_schedule_details[props.index].end_time : null}              
                    readOnly
                  />
                  <Form.Control.Feedback type="invalid">
                    <ErrorMessage component="div" name={`pov_schedule_details.${props.index}.end_time`}className="input-feedback"/>
                  </Form.Control.Feedback>
              </Form.Group>
          </Form.Row>
          <Form.Row>
              <Form.Group as={Col} sm={4} >
              <h6>Flexi Start</h6>
                  <DatePicker 
                    className="form-control"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    placeholder="On Duty"
                    selected={field.value?.pov_schedule_details[props.index]?.start_flexy_time ? field.value.pov_schedule_details[props.index].start_flexy_time: null}              
                    readOnly
                  />
                  <Form.Control.Feedback type="invalid">
                    <ErrorMessage component="div" name={`pov_schedule_details.${props.index}.start_flexy_time`}className="input-feedback" />
                  </Form.Control.Feedback>
              </Form.Group>
    
              <Form.Group as={Col} sm={4}>
              <h6>Flexi End </h6>
                  <DatePicker 
                    className="form-control"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    placeholder="On Duty"
                    selected={field.value?.pov_schedule_details[props.index]?.end_flexy_time ? field.value.pov_schedule_details[props.index].end_flexy_time: null}              
            
                    readOnly
                  />
                  <Form.Control.Feedback type="invalid">
                    <ErrorMessage component="div" name={`pov_schedule_details.${props.index}.end_flexy_time`}className="input-feedback" />
                  </Form.Control.Feedback>
              </Form.Group>
{/*     
              <Form.Group as={Col} sm={4}>
              <h6>Break </h6>
                  <DatePicker 
                    className="form-control"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    placeholder="On Duty"
                    selected={field.value?.pov_schedule_details[props.index]?.break_time != undefined ?field.value.pov_schedule_details[props.index].break_time : null}              

                    // selected={field.value.pov_schedule_details[props.index].break_time}              
                    readOnly
                  />
                  <Form.Control.Feedback type="invalid">
                    <ErrorMessage component="div" name={`pov_schedule_details.${props.index}.break_time`}className="input-feedback" />
                  </Form.Control.Feedback>
          </Form.Group> */}
          </Form.Row>
          
          </div>
          </Collapse>
          :null
        }
      
      </div>
      )}
    </Field>);
}


  const onSelectTimeHandlerStd = (data, index,setFieldValue,sched_type) => {
    
    if(data!==null){
      var onDuty = data;
      var offDuty = new Date(); 
      var breakTime = new Date(); 

      breakTime.setMinutes(0); 
      breakTime.setHours(1)

      offDuty.setMinutes(onDuty.getMinutes()); 
      offDuty.setHours( onDuty.getHours() + 9 ); 

      setFieldValue(sched_type + 'schedule_details['+index+'].start_time', onDuty); 
      setFieldValue(sched_type + 'schedule_details['+index+'].end_time',offDuty); 
      setFieldValue(sched_type + 'schedule_details['+index+'].start_flexy_time', onDuty); 
      setFieldValue(sched_type + 'schedule_details['+index+'].end_flexy_time',offDuty); 
      setFieldValue(sched_type + 'schedule_details['+index+'].break_time',breakTime); 
    }else{
      setFieldValue(sched_type + 'schedule_details['+index+'].start_time', null); 
    }

  };

  const onSelectTimeHandlerStdOnTimezone = (data, index,setFieldValue,sched_type,offset_data, is_flex = false) => {
    
    if(data!==null){
      // only changes for flex
      if(is_flex){
        
        var onDuty = data;
      // var offDuty = new Date(); 
      var breakTime = new Date(); 

      
      var employeeDuty = new Date(onDuty.getTime() +(offset_data* 1000))
      breakTime.setMinutes(0); 
      breakTime.setHours(1)

    
      var offDuty = new Date(onDuty.getTime() +(offset_data* 1000) + (9*3600*1000))
      setFieldValue(sched_type + 'schedule_details['+index+'].start_flexy_time', employeeDuty); 
      setFieldValue(sched_type + 'schedule_details['+index+'].end_flexy_time',offDuty); 

      } 
      else{
        var onDuty = data;
      // var offDuty = new Date(); 
      var breakTime = new Date(); 

      var employeeDuty = new Date(onDuty.getTime() +(offset_data* 1000))

      breakTime.setMinutes(0); 
      breakTime.setHours(1)

      var offDuty = new Date(onDuty.getTime() +(offset_data* 1000)+ (9*3600*1000))

      setFieldValue(sched_type + 'schedule_details['+index+'].start_time', employeeDuty); 
      setFieldValue(sched_type + 'schedule_details['+index+'].end_time',offDuty); 
      setFieldValue(sched_type + 'schedule_details['+index+'].start_flexy_time', employeeDuty); 
      setFieldValue(sched_type + 'schedule_details['+index+'].end_flexy_time',offDuty); 
      setFieldValue(sched_type + 'schedule_details['+index+'].break_time',breakTime);
      }

    }else{
      setFieldValue(sched_type + 'schedule_details['+index+'].start_time', null); 
    }

  };


   const onSelectTimeHandlerFlexi = (data, index,setFieldValue,sched_type) => {
    if(data!==null){
      var onDuty = data;
      var offDuty = new Date(); 

      offDuty.setMinutes(onDuty.getMinutes()); 
      offDuty.setHours( onDuty.getHours() + 9 ); 

      setFieldValue(sched_type + 'schedule_details['+index+']start_flexy_time', onDuty); 
      setFieldValue(sched_type +  'schedule_details['+index+']end_flexy_time',offDuty); 
    }else{
      setFieldValue(sched_type + 'schedule_details['+index+'].start_flexy_time', null); 
    }

  };
// (date,props.index,form.setFieldValue,"end_time",props.offset_data)
  const onSelectTimeHandlerSchedule = (data, index,setFieldValue,sched_type,contrast_too,offset_data) => {
    if(data!==null){
        var onDuty = data;
        var employeeDuty = new Date(); 
  

        // employeeDuty.setMinutes(onDuty.getMinutes()); 
        // employeeDuty.setHours( onDuty.getHours() + (offset_data/3600) ); 
        employeeDuty = new Date(onDuty.getTime() +(offset_data* 1000))
        setFieldValue(sched_type + 'schedule_details['+index+'].'+contrast_too,employeeDuty); 
    // setFieldValue("pov_"+ type, employeeDuty)
    }else{
        setFieldValue(sched_type + 'schedule_details['+index+'].'+contrast_too, null) 
    }

  };



export {
  Scheduledetails,
  ScheduledetailsWithTimezone,
  onSelectTimeHandlerStd,
  onSelectTimeHandlerFlexi,
  FlexibleSchedDetailsFormWithTimezone,
  StandardSchedDetailsForm,
  StandardSchedDetailsFormWithTimezone,
  FlexibleSchedDetailsForm,
  ScheduleHolidayPolicy,
  SchedulePolicy,
  WorkDays
}