import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./PageNotAllowed.css";
import { ContainerHeader,Content,ContainerWrapper,ContainerBody } from '../../components/GridComponent/AdminLte.js';

class PageNotAllowed extends Component {
  render() {
    return  <ContainerWrapper>
                <ContainerHeader>
                  <div className="page-not-allowed-box">
                    <span className="page-not-allowed-code"> 403 </span>
                    <br/>
                    <span className="page-not-allowed-oops"> Oops!</span><br/>
                    <span className="page-not-allowed-message">You can't access this page! </span>
                    <br/>
                    <br/>
                        <Link className="btn btn-primary" to={ global.links.dashboard }>
                          <i className="fa fa-arrow-circle-left" />
                          &nbsp; Go back to Dashboard
                        </Link>
                    <br/>
                    <br/>
                  </div>
                </ContainerHeader>                
            </ContainerWrapper>;
  }
}

export default PageNotAllowed;








